@import '../variables.scss';

@mixin absolute-full {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin full-width($padding: $content-padding) {
    width: calc(100% + calc($padding * 2));
    margin-left: -(calc($padding));
}

/*
    https://front-back.com/expand-clickable-areas-for-a-better-touch-experience/
    to increase clickarea of element
*/
@mixin area-expansion-after($top: $content-padding, $left: $top, $bottom: $top, $right: $left) {
    &::after {
        content: '';
        position: absolute;
        top: -$top;
        bottom: -$bottom;
        left: -$left;
        right: -$right;
    }
}

@mixin link-highlight($baseColor: var(--color-endeavour), $hoverColor: var(--color-roseOfSharon)) {
    color: $baseColor;
    cursor: pointer;

    &:hover {
        color: $hoverColor;
        text-decoration: underline;
    }
}

@mixin swiper-slide-override() {
    &:global(.swiper-slide) {
        @content;
    }
}

@mixin swiper-button-disabled-override() {
    & :global(.swiper-button-disabled) {
        @content;
    }
}

@mixin tooltip-override() {
    & :global(.tippy-box) {
        @content;
    }
}

@mixin tooltip-content-override() {
    & :global(.tippy-content) {
        @content;
    }
}

@mixin google-maps-style-override() {
    :global(.gm-style) {
        @content;
    }
}
