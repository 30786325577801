@import '@/assets/css/index.scss';

.showMore {
    cursor: pointer;
    color: var(--color-endeavour);
    white-space: nowrap;
}

.showMoreWithIcon {
    @include flex-gap-horizontal($content-padding);
    @include area-expansion-after();
    position: relative;
    margin-top: 18px;
    margin-left: auto;
    color: var(--color-endeavour);
    font-size: $font-size-small;
    cursor: pointer;
}
