@import '@/assets/css/index.scss';

.wrapper {
    position: relative;
    background-color: var(--color-white);

    @include desktop {
        background-color: unset;
    }
}

.borderBoxTitle {
    position: absolute;
    top: -15px;
    background-color: var(--color-white);
    font-size: 24px;
    margin-left: -22px;
    padding: 0 22px;
    font-weight: bold;
}

.contentBoxTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -5px;
}

.topRightAbsoluteWrapper {
    position: absolute;
    top: $content-padding;
    right: calc(2 * $content-padding);
    margin-right: -$content-padding;
    display: flex;
    flex-direction: row;
    column-gap: $content-padding--small;

    @include desktop {
        top: $content-padding-desktop--small;
        right: $content-padding;
        margin-right: unset;
    }
}

.titledBorderBox {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
    margin-top: 30px;
    padding: 40px 60px 30px 60px;
    border: 1px solid var(--color-alto);
    border-radius: 5px;
}
